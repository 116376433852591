import React, { useEffect, useState }  from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import background from "../../assets/login1.png";
import logo from "../../assets/logo.png";
import axios from 'axios';
import { Modal } from "react-bootstrap";

const Login = () => {
  const [cpf_user, setCpf_user] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const parsedURL = localStorage.getItem('urlParsed');
  const [modalOpen,setModalOpen] = useState(false)

  const [formState, setFormState] = useState({
    senha: "",
    nome: "",
    cpf: "",
    email: "",
    cnpj: "",
    confirm: "",
    isPasswordMatch: ""
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log(parsedURL)
    try {      
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        const resposta = await axios.post(
            `${parsedURL}post/login`,
            {
                cpf: cpf_user,
                password: password
                
            },
            { headers }
        );
        const data = resposta.data.user_info;
        const token = resposta.data.token;
        console.log(data);
        console.log(resposta);
        if (data.active === 'S') {
          const currentTime = Date.now()
            localStorage.setItem('token', token);
            localStorage.setItem('typeUser',data.id_tipo_usuario);
            localStorage.setItem('idUser',data.id_usuario);
            localStorage.setItem('userName',data.nome);
            localStorage.setItem('idEnt',data.id_entidade);
            setLoading(false);
            localStorage.setItem('user_login_time',currentTime.toString());
            window.location.href = `${window.location.origin}/home`
        } else {

            toast.error('Usuario Bloqueado', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    } catch (error) {
      console.log(error);
        toast.error('Usuario ou senha incorreto!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
      handleLogin(event); 
  }
};

const handleModal = () =>{
  setModalOpen(true);
}
const handleCloseModal = () =>{
  setModalOpen(false);
}
const handleSendUser = (event) => {
  event.preventDefault();
  console.log(formState);
  var cpfreplace = formState.cpf.replace(/[^A-Z0-9]/g, '')
  var cnpjreplace = formState.cnpj.replace(/[^A-Z0-9]/g, '')
  try {
    axios
      .post(`${parsedURL}post/cadastro/usuario`, {
        ...formState,
        cpf: cpfreplace,
        cnpj: cnpjreplace
      },{})
      .then((response) => {
        console.log('Formulário enviado com sucesso!');
        setFormState({
          cpf: '',
          senha: '',
          confirm: '',
          nome: '',
          email: '',
          cnpj: '',
          isPasswordMatch: '',
        });
        alert('Solicitação enviada com sucesso! /n O acesso será liberado após a aprovação da empresa de acordo com o CNPJ informado');
      })
      .catch((error) => {
        console.log('Erro ao enviar o formulário:', error);
        alert('Ocorreu um erro ao enviar o formulário. Por favor, tente novamente.');
      });
  } catch (error) {
    console.log(error);
    alert('Ocorreu um erro ao enviar o formulário. Por favor, tente novamente.');
  }
};

const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "cpf") {
      const formattedValue = formatCPF(value);
      setFormState(prevState => ({
        ...prevState,
        [name]: formattedValue
      }));
    } else if (name === "cnpj"){
      const formattedValue = formatCNPJ(value);
      setFormState(prevState => ({
        ...prevState,
        [name]: formattedValue
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

const formatCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
const formatCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, "");
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

const handleConfirmPassword = (event) => {
  const { value } = event.target;
  const { senha } = formState;

  if (value !== senha) {
    setFormState((prevState) => ({
      ...prevState,
      confirm: value,
      isPasswordMatch: false,
    }));
  } else {
    setFormState((prevState) => ({
      ...prevState,
      confirm: value,
      isPasswordMatch: true,
    }));
  }
};


console.log(formState.isPasswordMatch)
  return (
    <>
    <body className="my-login-page">
      <section className="h-100" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
        <div className="container h-100">
          <div className="row justify-content-md-center h-100">
            <div className="card-wrapper">
              <div className="brand" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo} style={{ width: '200%' }}/>
              </div>
              <div className="card fat card-fat" >
                <div className="card-body">
                  <h5 className="card-title text-center">Login</h5>
                  <div onKeyDown={handleKeyDown}>
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={(e) => setCpf_user(e.target.value)} value={cpf_user} />
                      <label htmlFor="floatingInput">Usuário</label>
                    </div>
                    <div className="form-floating">
                      <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                      <label htmlFor="floatingPassword">Senha</label>
                    </div>
                    <div className="form-group text-center mb-3"/>
                    <div className="form-group text-center mb-3">
                      <Link to="/home"><button type="button" className="btn btn-outline-primary btn-md" onClick={handleLogin}>Entrar</button></Link>
                    </div>
                    <div className="d-flex justify-content-center mt-4"><div type="button" className="btn btn-outline-secondary btn-sm px-1 py-0 " onClick={handleModal}>Solicite seu cadastro</div></div>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  Copyright &copy; 2024 &mdash; Transtec World
                </div>
              </div>
            </div>
            <div className={`modal ${modalOpen ? 'd-block' : ''} `} tabIndex="-1" role="dialog" style={{display: modalOpen ? 'block' : 'none',position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '30%',
              height: 'auto',
              overflow: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',}}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="registrationModalLabel">Cadastro</h5>
                  <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSendUser}>
                    <div class="mb-3">
                      <label for="cpf" class="form-label">CPF:</label>
                      <input type="text" class="form-control" name="cpf" value={formState.cpf} onChange={handleChange} id="cpf" required maxLength={11}/>
                    </div>
                    <div class="mb-3">
                      <label for="senha" class="form-label">Senha:</label>
                      <input type="password" class="form-control" name="senha" value={formState.senha} onChange={handleChange} id="senha" required />
                    </div>
                    <div class="mb-3">
                      <label for="confirm" class="form-label">Confirmar Senha:</label>
                      <input type="password" class={`form-control ${formState.isPasswordMatch ? 'is-valid' : 'is-invalid'}`} name="confirm" value={formState.confirm} onChange={handleConfirmPassword} id="confirm" required />
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">Nome:</label>
                      <input type="text" class="form-control" name="nome" value={formState.nome} onChange={handleChange} id="nome" required />
                    </div>
                    <div class="mb-3">
                      <label for="email" class="form-label">E-mail:</label>
                      <input type="email" class="form-control" name="email" value={formState.email} onChange={handleChange} id="email" aria-describedby="emailHelp" required />
                    </div>
                    <div class="mb-3">
                      <label for="cnpj" class="form-label">CNPJ da Empresa:</label>
                      <input type="text" class="form-control" name="cnpj" value={formState.cnpj} onChange={handleChange} id="cnpj" required maxLength={14}/>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-success"onClick={handleSendUser} disabled={!formState.isPasswordMatch}>Cadastrar</button>
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancelar</button>
                </div>
              </div>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </section>
      </body>
    </>
  );
};

export default Login;
