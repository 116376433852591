import React, { useState, useEffect, useCallback} from "react";
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import DataFat from "./cad_entidade_data_fat";
import axios from "axios";
import Search from "@mui/icons-material/Search.js";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Modal, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useAuth } from "../../context/AuthContext";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FileIcon, defaultStyles } from 'react-file-icon';

const CadDevolucao = () => {
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [howModal, setShowModal] = useState(false);
  const [margens, setMargens] = useState([]);
  const [tipocc, setTipocc] = useState([]);
  const [veicul, setVeiculo] = useState([]);
  const [armador, setArmador] = useState([]);
  const [entidad, setEnti] = useState([]);
  const [selectType, setSelectType] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codAgendamento = searchParams.get('codAgendamento');
  const [readOnly, setReadOnly] = useState(false);
  const { permissions } = useAuth();
  const [files, setFiles] = useState([])
  const [reg, setReg] = useState(false);
  const [handleButtonBlockSave, setHandleButtonBlockSave] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  

  const [formState, setFormState] = useState({
    numeracaoContainer: "",
    codArmador: "",
    codTipo: "",
    codResp: "",
    navio: "",
    viagem: "",
    codVeiculo: "",
    codMotorista: "",
    dataHoraPrevista: "",
    codMargem: "",
    bl: "",
    obs: "",
    codTypeAgenda: 2,
    cancelamento: ""
  });

  const [formMotor, setFormMotor] = useState({
    cpf: "",
    razao_social: "",
    cnh: "",
    placa: ""
  });

  useEffect(() => {
    handleGetMargens();
    handleGetTipo();
    handleGetArmador();
    handleGetCliente();
  }, []);

  const update ={
    codMotorista:formState.codMotorista,
    codVeiculo:formState.codVeiculo
  }

  useEffect(() => {
    const fetchData = async () => {

      if (codAgendamento !== null) {
        await handleGetDevolucao2();
        if (permissions.cliente === true || permissions.clienteSup === true) {
          setReadOnly(true);
        }
      };
    }
    fetchData();
  }, [codAgendamento]);

  const handleGetDevolucao2 = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/devolucao2?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormMotor(response.data.content);
        setFormState(response.data.content);
        const { dataHoraPrevista } = response.data.content;
        updateDatahora(dataHoraPrevista)

      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateDatahora = (value) => {
    if (value) {
      const [dt, horaCompleta] = value.split(' ');
      const [hora, minutos] = horaCompleta.split(':');
      console.log(dt);
      const date = new Date(value);
      console.log(date);
      const data = date.toISOString().slice(0, 10);

      setdatahora({
        data: data,
        hora: `${hora}:${minutos}`
      });
    }
  };

  const handleGetMargens = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/margem`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setMargens(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetTipo = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/tipocc`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setTipocc(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetArmador = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/armador`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setArmador(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetCliente = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/cliente`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEnti(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRowDoubleClick = (params) => {
    setFormState(prevState => ({
      ...prevState,
      codResp: params.data.id_entidade,
      cliente: params.data.razao_social
    }));
    setShowConfirmationModal(false);
  };

  const gerarPdf = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/gerar_pdf/devolucao_importacao?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob',
        }
      );
      if (response.status === 200) {
        // Cria um arquivo blob a partir da resposta
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Erro ao gerar o PDF:', response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //handle que verifica se a placa ja existe no DB
  const handlePlacaBlur = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/veiculos2?placa=${formMotor.placa}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 208) {
        console.log(response.data.content[0].codVeiculo)
        setFormState((prevState) => ({
          ...prevState,
          codVeiculo: response.data.content[0].codVeiculo,
        }));
      }
    } catch (error) {
      console.log(error)
    }
    console.log(formState.codVeiculo)
  };

  //handle que verifica se o CPF ja existe no DB
  const handleCPFBlur = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/motorista2?cnpj_cpf=${formMotor.cpf}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 208) {
        console.log(response.data.content[0])
        // Preenche automaticamente o formulário se o motorista já existir
        setFormState((prevState) => ({
          ...prevState,
          codMotorista: response.data.content[0].id_entidade,
        }));
        setFormMotor((prevState) => ({
          ...prevState,
          cnh: response.data.content[0].cnh,
          razao_social: response.data.content[0].razao_social,
        }));
        setReg(true);
      }
      else if(response.status === 200){
        toast.error('Cadastro não encontrado\n Redirecionando para a pagina de cadastro...', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.open('/cadastro_entidade?codCadastro=2', '_blank', 'noopener,noreferrer');
        }, 3000);
        console.log(response)
      }
    } catch (error) {
      // Se o motorista não for encontrado, cria um novo cadastro
      console.log(error)
    }
    console.log(formState.codMotorista)
    return { reg }
  };

  const postCPF = async () => {
    let codMoto = null
      try {
        const postResponse = await axios.post(
          `${parsedURL}post/cadastro/motorista`,
          {
            cnpj_cpf: formMotor.cpf,
            razao_social: formMotor.razao_social,
            cnh: formMotor.cnh,
            doc_type: 'CPF',
            M: 1
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (postResponse.status === 200) {
          console.log(postResponse.data.codMotorista);
          codMoto = postResponse.data.codMotorista;
        }
      } catch (postError) {
        console.error("Erro ao cadastrar motorista:", postError);
      }
      return codMoto;
  }

  const postPlaca = async () => {
    let codVeic = null
      try {
        const postVeiculo = await axios.post(
          `${parsedURL}post/cadastro/veiculo`,
          {
            placa: formMotor.placa,
            tipo: 'R',
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (postVeiculo.status === 200) {
          console.log(postVeiculo.data.codVeiculo);
          codVeic = postVeiculo.data.codVeiculo;
        }
      } catch (postError) {
        console.error("Erro ao cadastrar motorista:", postError);
      }
      return codVeic;
  };

  const handleClickSeach = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetCliente();
    setShowConfirmationModal(true);
  };
  
  const formatCnpj = (params) => {
    const cnpj = params.value;
    if (!cnpj) return '';
  
    const cnpjFormatted = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return cnpjFormatted;
  };

  const validateCol = () =>{
    if(entidad){
      if (localStorage.typeUser === 200 || localStorage.typeUser === '200'){
        return true
      } 
      else{
        return false
      }
    }
  }

  const columns = [
    {
      field: "id_entidade",
      headerName: "ID",
      width: 50
    },
    {
      field: "razao_social",
      headerName: "Razão Social",
      width: 200,
      filter: true,
      floatingFilter: true
    },
    {
      field: "cnpj_cpf",
      headerName: "CNPJ",
      width: 180,
      filter: true,
      floatingFilter: true,
      valueFormatter: formatCnpj,
      //hide: validateCol
    }
  ];

  const formatContainerNumber = (value) => {
    // Remove todos os caracteres não alfanuméricos
    let formatted = value.replace(/[^a-zA-Z0-9]/g, '');
    // Converter para maiúsculas
    formatted = formatted.toUpperCase();
    // Limitar a 11 caracteres
    formatted = formatted.slice(0, 11);
    // Formatar como XXXX 000.000-0
    if (formatted.length > 0) {
      let parts = [
        formatted.slice(0, 4),
        formatted.slice(4, 7),
        formatted.slice(7, 10),
        formatted.slice(10, 11)
      ];
      formatted = parts[0];
      if (parts[1]) formatted += ' ' + parts[1];
      if (parts[2]) formatted += '.' + parts[2];
      if (parts[3]) formatted += '-' + parts[3];
    }
  return formatted;
  };

  const calculateCheckDigit = (containerNumber) => {
    const number = containerNumber.replace(/[^A-Z0-9]/g, '');
    if (number.length !== 11) return false;
    console.log(containerNumber)
    const values = {
      'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19,
      'J': 20, 'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29,
      'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
    };

    let sum = 0;
    for (let i = 0; i < 10; i++) {
      let char = number[i];
      let value = values[char] || parseInt(char, 10);
      sum += value * Math.pow(2, i);
    }

    const checkDigit = sum % 11;
    return checkDigit === 10 ? 0 : checkDigit;
  };
  
  const safeToString = (value) => {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return String(value);
    if (value && typeof value === 'object' && 'toString' in value) {
      const str = value.toString();
      if (str === '[object Object]') return '';
      return str;
    }
    return '';
  };
  const [showTooltip, setShowTooltip] = useState(false);

  const validateContainerNumber = () => {
    console.log(formState.numeracaoContainer)

    const valueCC = safeToString(formState.numeracaoContainer);
    const cleanNumber = valueCC.replace(/[^A-Z0-9]/g, '');
    if (cleanNumber.length !== 11) return 'Número de contêiner deve ter 11 caracteres';
    
    const calculatedCheckDigit = calculateCheckDigit(cleanNumber);
    const providedCheckDigit = parseInt(cleanNumber[10], 10);

    if (calculatedCheckDigit === providedCheckDigit) {
      setShowTooltip(false);
      return true;
    } else {
      setShowTooltip(true);
      console.log(calculatedCheckDigit)
      return 'Número de contêiner inválido: dígito de verificação incorreto';
    }
  };

  // useEffect para formatar e validar o numero container
  useEffect(() => {
    const cleanNumber = formState.numeracaoContainer.replace(/[^A-Z0-9]/g, '');
    if (cleanNumber.length === 11) {
      validateContainerNumber(formState.numeracaoContainer);
    } else {
      setShowTooltip(false);
    }
  }, [formState.numeracaoContainer]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'numeracaoContainer') {
      const valorContainer = formatContainerNumber(value);
      console.log(valorContainer.replace(/[^A-Z0-9]/g, ''))
      setFormState(prevState => ({
        ...prevState,
        numeracaoContainer: valorContainer
      }))
    }
    else{
      setFormState(prevState => ({
        ...prevState,
        [name]: value.toUpperCase(),
      }));
      setFormMotor(prevState => ({
        ...prevState,
        [name]: value.toUpperCase(),
      }));
    }
  };

  const handleSubmit = async () =>{ 
    if(!validateFields()){
      return;
    };
    setHandleButtonBlockSave(true);

    let codVeic;
    let codMoto;
    if((!formState.codMotorista || formState.codMotorista === '') && (formMotor.cpf || formMotor.cpf !=='') ){
      codMoto = await postCPF();
    }
    else{
      codMoto = formState.codMotorista;
    }
    if((!formState.codVeiculo || formState.codVeiculo === '') && (formMotor.placa || formMotor.placa !=='') ){
      codVeic = await postPlaca();
    }
    else{
      codVeic = formState.codVeiculo;
    }
    console.log(codMoto)
    console.log(codVeic)
    
    if (!codAgendamento) {
      await handlePostForm(codMoto, codVeic);
    } else {
      await executeUpdate(codMoto, codVeic);
    };
    
    if(files.length !== 0){
      await handleFileUpload();
    }

    /*setTimeout(() => {
      window.location.href = `${window.location.origin}/consulta_devolucao`
    }, 3000)*/
  }

  const handlePostForm = async (codMoto, codVeic) => {
    var ccreplace = formState.numeracaoContainer.replace(/[^A-Z0-9]/g, '')
    try {
      const response = await axios.post(
        `${parsedURL}post/cadastro/devolucao`, 
        {
          ...formState,
          numeracaoContainer: ccreplace,
          codMotorista: codMoto,
          codVeiculo: codVeic,
        }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Dados enviados com sucesso:', response.data);
      toast.success('Pré-registro efetuado com sucesso!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Faça o que for necessário após o envio bem-sucedido dos dados
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Faça o que for necessário em caso de erro no envio dos dados
    }
  };
  
  const handleCheck = async () =>{
    setHandleButtonBlockSave(true);

    if (!codAgendamento) {
      await handleSubmit();
    } else {
      await executeUpdate();
    };
    setTimeout(() => {
      window.location.href = `${window.location.origin}/consulta_devolucao`
    }, 1000)
  }

  const executeUpdate = async (codMoto,codVeic) => {
  console.log(update)
  console.log(codMoto)
  try {
    const response = await axios.put(
      `${parsedURL}put/cadastro/devolucao?codAgendamento=${codAgendamento}`,
      {
        codMotorista: codMoto,
        codVeiculo: codVeic
      },
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }
    );
    console.log('Dados enviados com sucesso:', response.data);
    toast.success('Dados atualizados!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setHandleButtonBlockSave(false);
    setTimeout(() => {
      window.location.href = `${window.location.origin}/consulta_devolucao`
    }, 1000)
  } catch (error) {
    console.error('Erro ao enviar os dados:', error);
    toast.error('Erro ao enviar os dados!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  };
}

  const [datahora, setdatahora] = useState({ data: "", hora: "" })

  const horaChange = (event) => {
    const { name, value } = event.target;
    setdatahora(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return (`${year}-${month}-${day}`);
  };

  const parseDateMax = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    date.setDate(date.getDate() + 2);

    const newDay = String(date.getDate()).padStart(2, '0');
    const newMonth = String(date.getMonth() + 1).padStart(2, '0');
    const newYear = date.getFullYear();

    return `${newYear}-${newMonth}-${newDay}`;
  };

  const today = new Date().toLocaleDateString('pt-BR');

  console.log(parseDate(today))

  const validateDateTime = (event) => {
    const selectedDateTime = new Date(event.target.value);
    const selectedDT = selectedDateTime;
    selectedDT.setUTCHours(0, 0, 0, 0);
    const now = new Date();
    const currentDate = now;
    currentDate.setUTCHours(0, 0, 0, 0);

    const day = selectedDateTime.getDay();

    if (selectedDT < currentDate) {
      toast.error('Não é possível agendar para uma data passada!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setdatahora(prevState => ({
        ...prevState,
        data: ""
      }));
      return;
    }

    if (day === 6) {
      toast.error('Não é possivel agendamentos aos domingos!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setdatahora(prevState => ({
        ...prevState,
        data: ""
      }));
      return;
    }

    setdatahora(prevState => ({
      ...prevState,
      data: selectedDateTime.toISOString().slice(0, 10)
    }));
    console.log(datahora.data)
  };

  const generateTimeSlots = () => {
    const now = new Date();
    const selectedDate = new Date(datahora.data);
    const dayOfWeek = selectedDate.getDay();
    const startHour = dayOfWeek === 5 ? 7 : 7;
    const endHour = dayOfWeek === 5 ? 10 : 15;
    let timeSlots = [];
    const currentDay = new Date(datahora.data).getUTCDate();
    const today = now.getDate();
    for (let hour = startHour; hour <= endHour; hour++) {
      const slotTime = new Date();
      slotTime.setHours(hour, 0, 0, 0);

      if (currentDay === today) {
        const twoHoursAhead = new Date(now.getTime() + 1 * 60 * 60 * 1000);
        if (slotTime >= twoHoursAhead) {
          const formattedHour = hour.toString().padStart(2, '0');
          timeSlots.push(`${formattedHour}:00`);
        }
      } else {
        const formattedHour = hour.toString().padStart(2, '0');
        timeSlots.push(`${formattedHour}:00`);
      }
    }
    return timeSlots;
  };

  const timeSlots = generateTimeSlots();

  const agend = (`${datahora.data} ${datahora.hora}:00`)
  formState.dataHoraPrevista = agend

  const validaPDF = codAgendamento !== null;
  console.log(validaPDF)

  //anexar arquivos
  const handleAttachFiles = () => {
    setShowModal(true);    
  };

  const [isLoading, setIsLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const handleAppendFile = useCallback(async (event) => {
    const selectedFiles = event.target.files;
    let newTotalFileSize = totalFileSize;
    const newFiles = [...files];
    const newThumbnails = [...thumbnails];

    for (const file of selectedFiles) {
      if (newTotalFileSize + file.size > 5 * 1024 * 1024) {
        toast.error('O tamanho total dos arquivos não pode exceder 5MB.', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      newTotalFileSize += file.size;
      const thumbnail = await generateThumbnail(file);
      newFiles.push(file);
      newThumbnails.push(thumbnail);
    }

    setFiles(newFiles);
    setThumbnails(newThumbnails);
    setTotalFileSize(newTotalFileSize);
  },);


  const [thumbnails, setThumbnails] = useState([]);

  const [fileData, setFileData] = useState([]);

  const handleFileUpload = useCallback(async () => {
    setIsLoading(true);
    setUploaded(false);

    for (const file of files) {
      const formData = new FormData();
      formData.append('arquivo', file);
      var ccreplace = formState.numeracaoContainer.replace(/[^A-Z0-9]/g, '')
      
      console.log(token)
      try {
        const response = await axios.post(
          `${parsedURL}post/devolucao/upload_file?cntnr=${ccreplace}`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 200) {
          console.log(response.data.content);
        }
      } catch (error) {
        toast.error('Erro ao concluir a operação!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    setIsLoading(false);
    setUploaded(true);
  }, [files, parsedURL, formState.numeracaoContainer, token]);

  const [totalFileSize, setTotalFileSize] = useState(0);

  const handleModalConfirm = () => {
    setModalIsOpen(false);
  };
  
  useEffect(() => {
    setModalIsOpen(true);
    console.log(modalIsOpen)
  }, []);

  const handleCheckboxChange = (event) => {
    setFormState({
      ...formState,
      cancelamento: event.target.checked ? 1 : 0,
    });
    if (formState.cancelamento === 1){
      setFormState(prevState =>({
        ...prevState,
        codTypeAgenda: 3,
      }));
    }
    else {
      setFormState(prevState =>({
        ...prevState,
        codTypeAgenda: 2,
      }));
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formState.numeracaoContainer) newErrors.numeracaoContainer = "Campo obrigatório";
    if (!formState.codArmador) newErrors.codArmador = "Campo obrigatório";
    if (!formState.bl) newErrors.bl = "Campo obrigatório";
    if (!formState.codResp) newErrors.codResp = "Campo obrigatório";
    if (!datahora.data) newErrors.data = "Campo obrigatório";
    if (!datahora.hora) newErrors.hora = "Campo obrigatório";
    if (!formState.codMargem) newErrors.codMargem = "Campo obrigatório";
    if (!formState.codTipo) newErrors.codTipo = "Campo obrigatório";
    if (!formState.navio) newErrors.navio = "Campo obrigatório";
    {/*if (!formState.viagem) newErrors.viagem = "Campo obrigatório";*/}
    if (!formMotor.cpf) newErrors.cpf = "Campo obrigatório";
    if (!formMotor.cnh) newErrors.cnh = "Campo obrigatório";
    if (!formMotor.razao_social) newErrors.razao_social = "Campo obrigatório";
    if (!formState.placa) newErrors.placa = "Campo obrigatório";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAprove = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/valida_pre?ctnr=${formState.numeracaoContainer}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        handlePostAprov();
        toast.success('Pré registro aprovado com sucesso!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      else if(response.status === 208){
        setShowUpdateModal(true);
      }
    } catch (error) {
      console.log(error)
    }
  };
  
  const [showUpdateModal,setShowUpdateModal] = useState(false); 

  const handleUpdateModal = ()=>{
    setShowUpdateModal(false);
  }

  const handlePostAprov = async () => {
    console.log("test")
    try {
      const response = await axios.put(
        `${parsedURL}put/cadastro/devolucao2?codAgendamento=${codAgendamento}`,
        {
          sync: 1
        },
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log('Dados enviados com sucesso:', response.data);
      toast.success('Pré registro aprovado com sucesso!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    catch (error) {
      console.error('Erro ao enviar os dados:', error);

    }
  }
  
  const handleDownloadZip = async () => {
    try {
      const response = await axios.get(`${parsedURL}get/arquivos/download_devolucao_files?cntnr=${formState.numeracaoContainer}&codAgend=${codAgendamento}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${formState.booking}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error)
    }
  };

  const saveFileToCache = (file) => {
    // Obter o cache do navegador
    const cache = window.localStorage;
  
    // Gerar um ID único para o arquivo
    const fileId = `file_${Date.now()}_${file.name}`;
  
    // Salvar o arquivo no cache
    cache.setItem(fileId, JSON.stringify(file));
  
    return fileId;
  };

  const formatFileSize = useCallback((bytes) => {
    if (bytes < 1024) return `${bytes} B`;
    if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  }, []);

  useEffect(() => {
    console.log(files.lenght)
    if (formState.cancelamento ===1){
      setHandleButtonBlockSave(files.length === 0)
    }
    else{
      setHandleButtonBlockSave(false)
    }
  },);

  const generateThumbnail = useCallback((file) => {
    return new Promise((resolve) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const size = 50;
            canvas.width = size;
            canvas.height = size;
            ctx.drawImage(img, 0, 0, size, size);
            resolve(canvas.toDataURL());
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        resolve(null);
      }
    });
  }, []);

  const handleRemoveFile = useCallback((index) => {
    const newFiles = [...files];
    const newThumbnails = [...thumbnails];
    const removedFileSize = newFiles[index].size;
    newFiles.splice(index, 1);
    newThumbnails.splice(index, 1);
    setFiles(newFiles);
    setThumbnails(newThumbnails);
    setTotalFileSize((prevSize) => prevSize - removedFileSize);
  }, [files, thumbnails]);

  console.log(localStorage.typeUser)

  return (
    <>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
          <form class="row g-3">
            <div class="col-md-4">
              <div class="mb-3">
                <label for="numeracaoContainer" class="form-label fw-bold">Container:</label>
                <OverlayTrigger
                  trigger="manual"
                  placement="top"
                  show = {showTooltip}
                  overlay={
                    <Tooltip id="container-number-tooltip" className="custom-red-tooltip">
                      Numeração Incorreta!
                    </Tooltip>
                  }>
                  <div className="input-group">
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="numeracaoContainer"
                      name="numeracaoContainer" value={formState.numeracaoContainer} onChange={handleChange}
                      required maxLength={14}/>
                    {errors.numeracaoContainer && <span className="text-danger">{errors.numeracaoContainer}</span>}
                  </div>
                </OverlayTrigger>
              </div>
              <div class="mb-3">
                <label for="codArmador" class="form-label fw-bold">Armador:</label>
                <select value={formState.codArmador} disabled={readOnly} onChange={handleChange} name="codArmador" 
                id="inputState" class="form-select transparent-input">
                  <option selected>Selecione...</option>
                  {armador.map((opcao) => (
                    <option key={opcao.id_entidade} value={opcao.id_entidade}>{opcao.fantasia}</option>
                  ))}
                </select>
                {errors.codArmador && <span className="text-danger">{errors.codArmador}</span>}
              </div>
              <div class="mb-3">
                <label for="bl" class="form-label fw-bold">BL:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input " id="bl" name="bl" 
                value={formState.bl} onChange={handleChange} />
                {errors.bl && <span className="text-danger">{errors.bl}</span>}
              </div>
              <div class="mb-3">
                <label class="hfx form-label fw-bold">Importador:</label>
                <div class="input-group">
                  <input type="text" disabled={readOnly} value={formState.cliente} readOnly={true} 
                  class="form-control transparent-input" />
                  <button type="button" disabled={readOnly} onClick={() => handleClickSeach("codResp")} 
                  class="input-group-text btn-primary btn"><Search /></button>
                </div>
                  {errors.codResp && <span className="text-danger">{errors.codResp}</span>}
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label for="codMargem" class="form-label fw-bold">Margem:</label>
                <select value={formState.codMargem} disabled={readOnly} onChange={handleChange} name="codMargem"
                id="inputState" class="form-select transparent-input">
                  <option selected value=''>Seguir apontamento do Armador...</option>
                  {margens.map((opcao) => (
                    <option key={opcao.codArea} value={opcao.codArea}>{opcao.descricao}</option>
                  ))}
                </select>
                {errors.codMargem && <span className="text-danger">{errors.codMargem}</span>}
              </div>
              <div class="mb-3">
                <label for="codTipo" class="form-label fw-bold">Tipo de Container:</label>
                <select disabled={readOnly} value={formState.codTipo} onChange={handleChange} name="codTipo" 
                id="inputState" class="form-select transparent-input">
                  <option selected>Selecione o tipo...</option>
                  {tipocc.map((opcao) => (
                    <option key={opcao.codTipo} value={opcao.codTipo}>{opcao.descricao}</option>
                  ))}
                </select>
                {errors.codTipo && <span className="text-danger">{errors.codTipo}</span>}
              </div>
              <div class="mb-4">
                <label for="navio" class="form-label fw-bold">Navio:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input" id="navio" name="navio" 
                value={formState.navio} onChange={handleChange} />
                {errors.navio && <span className="text-danger">{errors.navio}</span>}
              </div>
              <div className="d-flex">
                <div className="col-md-7">
                  <label for="data" className="form-label fw-bold">Data Prevista:</label>
                  <input type="date" disabled={readOnly} className="form-control transparent-input" id="data" name="data" 
                  min={parseDate(today)} max={parseDateMax(today)} value={datahora.data} onChange={validateDateTime} />
                  {errors.data && <span className="text-danger">{errors.data}</span>}
                </div>
                <div className="col-md-5">
                  <label for="hora" className="form-label fw-bold">Horário:</label>
                  <select className="form-control transparent-input" disabled={readOnly} id="hora" name="hora" 
                  value={datahora.hora} onChange={horaChange}>
                    <option selected value=''>--:--</option>
                    {timeSlots.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                  {errors.hora && <span className="text-danger">{errors.hora}</span>}
                </div>
              </div>
              {/*<div class="mb-3">
                <label for="viagem" class="form-label fw-bold">Viagem:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input" id="viagem" name="viagem" 
                value={formState.viagem} onChange={handleChange} />
                {errors.viagem && <span className="text-danger">{errors.viagem}</span>}
              </div>*/}
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label for="cpf" class="form-label fw-bold">CPF Motorista:</label>
                <div className="input-group">
                <input type="text" class="form-control transparent-input" id="cpf" name="cpf" value={formMotor.cpf} 
                onChange={handleChange} maxLength={11} pattern="[0-9]*" 
                inputMode="numeric" onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}/>
                <button type="button" onClick={() => handleCPFBlur()}
                class="input-group-text btn-success btn">Validar</button> 
                {errors.cpf && <span className="text-danger">{errors.cpf}</span>}
              </div>
              </div>
              <div class="mb-3">
                <label for="razao_social" class="form-label fw-bold">Nome:</label>
                <input type="text" class="form-control transparent-input" id="razao_social" name="razao_social" 
                value={formMotor.razao_social} onChange={handleChange} disabled={reg} readOnly/>
                {errors.razao_social && <span className="text-danger">{errors.razao_social}</span>}
              </div>
              {/*<div class="mb-3">
                <label for="cnh" class="form-label fw-bold">CNH:</label>
                <input type="text" class="form-control transparent-input" id="cnh" name="cnh" value={formMotor.cnh} 
                onChange={handleChange} disabled={reg} maxLength={9} minLength={9} pattern="[0-9]*" 
                inputMode="numeric" onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}/>
                {errors.cnh && <span className="text-danger">{errors.cnh}</span>}
              </div>*/}
              <div class="mb-3">
                <label for="placa" class="form-label fw-bold">Placa do Veiculo:</label>
                <input type="text" class="form-control transparent-input" id="placa" name="placa" value={formMotor.placa} 
                onChange={handleChange} onBlur={handlePlacaBlur} />
                {errors.placa && <span className="text-danger">{errors.placa}</span>}
              </div>
              <div class="mt-5 ms-5">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" checked={formState.cancelamento === 1} onChange={handleCheckboxChange} id="flexCheckDefault"/>
                    <label class="form-check-label" for="flexCheckDefault">Cancelamento de exportação</label>
                    {(formState.cancelamento === 1) && (<p className=" mt-2 text-danger">O anexo com autorização do armador é obrigatório.</p>)}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label for="obs" class="form-label fw-bold">Observações:</label>
              <textarea class="form-control transparent-input" rows="5" id="obs" name="obs" value={formState.obs} 
              onChange={handleChange}></textarea>
            </div>
            <div class="col-md-12">
              <button type="button" class="btn btn-success" disabled={handleButtonBlockSave} onClick={handleSubmit}>Salvar</button>
              <button type="button" class="btn btn-info m-2" onClick={gerarPdf} disabled={!validaPDF}>Gerar minuta em PDF</button>
              {(!permissions.cliente) && (!permissions.clienteSup) && (<button type="button" class="btn btn-success float-end m-2" onClick={handleAprove}>APROVAR</button>)}
              {(files.length !== 0) && (<p className="float-end m-2 mt-3">{files.length} Arquivos anexados.</p>)}
              {(permissions.cliente) || (permissions.clienteSup) && (<button type="button" class="btn btn-primary float-end m-2" onClick={handleAttachFiles}>Anexar Arquivos</button>)}
              {(!permissions.cliente) || (!permissions.clienteSup) && (<button type="button" class="btn btn-warning float-end m-2" onClick={handleDownloadZip}>Baixar Arquivos</button>)}
            </div>
          </form>
        </div>
        <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" 
        style={{ display: showConfirmationModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-warning">
                <h5 className="modal-title"><b>Selecione o Cliente</b></h5>
                <button type="button" className="close bg-danger text-white" aria-label="Close" 
                onClick={() => setShowConfirmationModal(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ">
                <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                  <AgGridReact
                    rowData={entidad}
                    columnDefs={columns}
                    rowSelection="single"
                    onRowDoubleClicked={handleRowDoubleClick}
                  />
                </div>
              </div>
              <div className="modal-footer ">
                <Link to={`/cadastro_entidade?codCadastro=3`} type="button" className="btn btn-info" 
                onClick={() => setShowConfirmationModal(false)} target="_blank" rel="noopener noreferrer">Cadastrar Novo</Link>
                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Fechar</button>
              </div>
            </div>
          </div>
        </div>
        {(modalIsOpen) && (<div isOpen={true} onRequestClose={handleModalConfirm} contentLabel="aviso devoluçao">
          <div className="modal d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-md modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-danger"><b className="mx-auto text-white fs-5">ATENÇÃO</b></div>
                <div className="modal-body">
                  <p>A hora prevista para a devolução é apenas para fim informativo. <br/> <b>A devolução será aceita até o fim do expediente no dia informado. (Seg-Sex até as 16:00 / Sab até as 11:00)</b><br/><br/><p className="text-danger fs-6">Caso a unidade não seja apresentada na data citada, uma nova solicitação deverá ser efetuada!</p></p>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handleModalConfirm}>OK</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
        {(showUpdateModal) && (<div isOpen={true} onRequestClose={handleUpdateModal} contentLabel="aviso cadastro existente">
          <div className="modal d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-danger"><b className="mx-auto text-white">ATENÇÃO</b></div>
                <div className="modal-body">
                  <p>Já existe uma pré-entrada para esta unidade! <br/> <b>Deseja continuar com a operação?</b></p>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handlePostAprov()}>SIM</div>
                  <div type="button" className="btn btn-danger" onClick={handleUpdateModal}>NÃO</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
      <Modal show={howModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar Arquivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Selecione os arquivos:</Form.Label>
            <Form.Control type="file" id='file' onChange={handleAppendFile} multiple />
          </Form.Group>
          <p className="text-danger">O tamanho total dos arquivos não deve ultrapassar o limite de 5Mb.</p>
          <div className="progress mb-3">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${(totalFileSize / (5 * 1024 * 1024)) * 100}%` }}
              aria-valuenow={totalFileSize}
              aria-valuemin="0"
              aria-valuemax={5 * 1024 * 1024}
            >
              {formatFileSize(totalFileSize)} / 5MB
            </div>
          </div>
          <div className="d-flex flex-wrap">
          {files.map((file, index) => (
            <div key={index} className="thumbnail-container position-relative m-2">
              {thumbnails[index] ? (
                <img src={thumbnails[index]} alt={`Thumbnail ${index}`} className="thumbnail" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
              ) : (
                <div style={{ width: '50px', height: '50px' }}>
                  <FileIcon extension={file.name.split('.').pop()} {...defaultStyles[file.name.split('.').pop()]} />
                </div>
              )}
              <button
                className="btn btn-danger btn-sm delete-button position-absolute top-0 end-0"
                onClick={() => handleRemoveFile(index)}
                style={{ padding: '0.1rem 0.3rem', fontSize: '0.7rem' }}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
          {isLoading && (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
            )}
            {uploaded && (
              <div className="d-flex justify-content-center">
                <div class="alert alert-success d-flex align-items-center" role="alert">
                  <CheckBoxIcon className="text-success"/>
                  <div>
                    Arquivos enviados com sucesso!
                  </div>
                </div>
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default CadDevolucao;
