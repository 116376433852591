import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar></Navbar>
      <div className="container-fluid py-4">
  <div className="row justify-content-center">
    <div className="col-12 col-lg-11">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100 rounded-3 shadow">
          <div className="card-header text-center mt-0 bg-custom wp-1"><h4>RETIRADA DE VAZIO</h4></div>
            <div className="card-body">
              {/*<h4 className="card-title text-center mb-4">RETIRADA DE VAZIO</h4>
              <div><hr className="divider" /></div>*/}
              <div className="d-grid gap-3">
                <a href="/consulta_agendamento" className="btn btn-outline-secondary py-3 disabled">
                  <h5 className="m-0 font">CONSULTA DE AGENDAMENTOS</h5>
                </a>
                <a href="/consulta_agendamento?openModal=true" className="btn btn-outline-secondary py-3 disabled">
                  <h5 className="m-0 font">NOVA SOLICITAÇÃO</h5>
                </a>
              </div>
            </div>
            <span className="text-danger text-center">*Função disponível em breve!</span>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 rounded-3 shadow">
          <div className="card-header text-center mt-0 bg-custom wp-1"><h4>DEVOLUÇÃO DE VAZIO</h4></div>
            <div className="card-body">
              {/*<h4 className="card-title text-center mb-4">DEVOLUÇÃO DE VAZIO</h4>
              <div><hr className="divider" /></div>*/}
              <div className="d-grid gap-3">
                <a href="/consulta_devolucao" className="btn btn-outline-primary py-3">
                  <h5 className="m-0">CONSULTA DE DEVOLUÇÕES</h5>
                </a>
                <a href="/consulta_devolucao?openModal=true" className="btn btn-outline-primary py-3">
                  <h5 className="m-0">NOVA SOLICITAÇÃO</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100 rounded-3 shadow">
            <div className="card-header text-center mt-0 bg-custom wp-1"><h4>CADASTROS</h4></div>
            <div className="card-body">
              {/*<h4 className="card-title text-center mb-4">CADASTROS</h4>
              <div><hr className="divider" /></div>*/}
              <div className="d-grid gap-3">
              <Link to={`/cadastro_entidade?codCadastro=1`} className="btn btn-outline-primary py-3" target="_blank" rel="noopener noreferrer">
                  <h5 className="m-0">CLIENTES</h5>
                </Link>
                <Link to={`/cadastro_entidade?codCadastro=2`} className="btn btn-outline-primary py-3" target="_blank" rel="noopener noreferrer">
                  <h5 className="m-0">MOTORISTAS</h5>
                </Link>
                <Link to={`/cadastro_entidade?codCadastro=3`} className="btn btn-outline-secondary py-3 disabled" target="_blank" rel="noopener noreferrer">
                  <h5 className="m-0">VISTORIADORES</h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Home;