import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ADDPerson from '@mui/icons-material/PersonAdd';
import { useAuth } from "../../context/AuthContext";

const Usuarios = () => {
  const [rowData, setRowData] = useState();
  const [rowDataPend, setRowDataPend] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();
	const { permissions } = useAuth();
  const [excludeModal, setExcludeModal] = useState(false);
  const [formState, setFormState] = useState({
    cpf: "",
    senha: "",
    email: "",
    cnpj: "",
    active: "",
    id_tipo_usuario: "",
    nome: "",
    id_usuario: ""
  })
  
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    handleGetUsuarios();
    handleGetUsuariosPend();
  }, []);

  const handleRowDoubleClick = (params) => {
    const user = params.data.id_usuario;
    console.log(user);
    handleGetSingleUsuario(user);
  };

  const handleGetSingleUsuario = async (user) => {
    console.log(user)
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/usuario?user=${user}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormState(response.data.content[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateCadastro = async () => {
    console.log(formState)
    try {
      const response = await axios.put(
        `${parsedURL}put/cadastro/usuario?user=${formState.id_usuario}`, formState,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        toast.success('Cadastro atualizado!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }

  const handleGetUsuarios = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/usuarios`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleGetUsuariosPend = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/usuariosPend`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowDataPend(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    { field: "nome", headerName: "Usuario", width: 350 },
  ];
  const columnsPend = [
    { field: "nome", headerName: "Usuario", width: 350 },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value,
    );
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleAprove = async () =>{
      console.log(formState)
      try {
        const response = await axios.put(
          `${parsedURL}put/cadastro/usuario?user=${formState.id_usuario}`, {...formState, active: 'S',id_entidade: localStorage.idEnt},
          {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          }
        );
        if (response.status === 200) {
          toast.success('Usuario ativado!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleGetUsuarios();
          handleGetUsuariosPend();
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao enviar os dados!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
  };
  const handleCancel = async() =>{
    console.log(formState)
    try {
      const response = await axios.put(
        `${parsedURL}put/cadastro/usuario?user=${formState.id_usuario}`, {...formState,active: 'P',id_entidade: 0, cnpj: ""},
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        toast.success('Usuario desvinculado!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleGetUsuarios();
        handleGetUsuariosPend();
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    };
  };
  console.log(localStorage.idEnt)
  const handleExcludeModalCancel = () => {
    setExcludeModal(false);
  };
  const openExcludeModal=()=>{
    setExcludeModal(true)
  }
  const handleExcludeModalConfirm = () => {
    setExcludeModal(false);
    handleCancel();
  };

  return (
    <>
      <Navbar />
      <div className="box-center1">
        <div className="row d-flex justify-content-between">
          <div className="card col-md-3">
              <legend className='fw-bold text-center mt-2 '>Usuarios Ativos</legend>
              <div><hr className="divider" /></div>
            <div className="example-header bg-light rounded border">
              <span className="mx-2 mt-2 mb-2"><b>Filtro:</b></span>
              <input type="text" className="mx-2 mt-2 mb-2 rounded border" id="filter-text-box" placeholder="Digite..."
                onInput={onFilterTextBoxChanged}/>
            </div>
            <div className="ag-theme-quartz" style={{ height: 500 ,'--ag-header-background-color': '#0d234d','--ag-header-foreground-color': 'white'}}>
              <AgGridReact
                ref={gridRef}
                localeText={AG_GRID_LOCALE_BR}
                rowData={rowData}
                columnDefs={columns}
                pagination={false}
                rowSelection="single"
                onRowDoubleClicked={handleRowDoubleClick}
              />
            </div>
          </div>
          <div className='card col-md-6'>
                  <legend className='fw-bold text-center mt-2 '>Informações da Conta:</legend>
                  <div><hr className="divider" /></div>
              <div className='col'>
                  <div type="label" className='fw-bol fs-5 mb-4'><b>Nome:</b> {formState.nome}</div>
                {(!permissions.clienteSup) && (<div>
                  <label for="id_tipo_usuario" class="form-label fw-bold mt-2">Tipo de Usuario:</label>
                  <select value={formState.id_tipo_usuario} onChange={handleChange} name="id_tipo_usuario"
                    id="inputState" class="form-select transparent-input" disabled={permissions.clienteSup}>
                    <option selected value="0">Selecione...</option>
                    <option value="125">T.I</option> 
                    <option value="150">Gerente Geral</option> 
                    <option value="200">Cliente</option> 
                    <option value="250">Cliente Master</option> 
                    <option value="300">Gate</option> 
                    <option value="320">Documental</option> 
                    <option value="340">Agendamento</option> 
                    <option value="350">Supervisor Documental</option> 
                    <option value="400">Avaria</option> 
                    <option value="450">Supervisor Avaria</option> 
                    <option value="500">Vistoria</option> 
                    <option value="520">Conferente</option> 
                    <option value="540">Oficina</option> 
                    <option value="550">Gerente Patio</option> 
                    <option value="1616">Desenvolvedor</option>
                  </select>
                </div>)}
                <div>
                  <label for="email" class="form-label fw-bold mt-2">E-mail:</label>
                  <input value={formState.email} onChange={handleChange} name="email"
                    id="text" class="form-control transparent-input" disabled={permissions.clienteSup}/>
                </div>
                <div>
                  <label for="cnpj" class="form-label fw-bold mt-2">CNPJ da Empresa:</label>
                  <input value={formState.cnpj} onChange={handleChange} name="cnpj"
                    id="inputState" class="form-control transparent-input" disabled={permissions.clienteSup}/>
                </div>
                <div>
                  <label for="active" class="form-label fw-bold mt-2">Status da Conta:</label>
                  <select value={formState.active} onChange={handleChange} name="active"
                    id="inputState" class="form-select transparent-input" disabled={permissions.clienteSup}>
                    <option selected value="0">Selecione...</option>
                    <option value="S">Ativa</option>
                    <option value="N">Inativa</option>
                  </select>
                </div>
                <div class="d-grid">
                  <label for="" class="row form-label fw-bold mt-3"></label>
                  {(!permissions.clienteSup) && (<div type="button" className='btn btn-primary mt-3' onClick={updateCadastro}>Salvar Alterações</div>)}
                  {(permissions.clienteSup && formState.active==='P') && (<div type="button" className='btn btn-success mt-3' onClick={handleAprove}>Aprovar Usuario</div>)}
                  {(permissions.clienteSup && formState.active==='S') && (<div type="button" className='btn btn-danger mt-3' onClick={openExcludeModal}>Desvincular Usuario</div>)}
                </div>
              </div>
          </div>
          <div className="card col-md-3">
            <legend className='fw-bold text-center mt-2'>Pendentes de Autorização</legend>
            <div><hr className="divider" /></div>
            <div className="ag-theme-quartz" style={{ height: 500,'--ag-header-background-color': '#0d234d','--ag-header-foreground-color': 'white' }}>
              <AgGridReact
                ref={gridRef}
                localeText={AG_GRID_LOCALE_BR}
                rowData={rowDataPend}
                columnDefs={columnsPend}
                pagination={false}
                rowSelection="single"
                onRowDoubleClicked={handleRowDoubleClick}
              />
            </div>
          </div>
        </div>
      </div>
      {(excludeModal) && (<div isOpen={true} onRequestClose={handleExcludeModalCancel} contentLabel="ATENÇÃO">
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-warning"><b className="mx-auto">DESVINCULAR USUARIO</b></div>
                <div className="modal-body">
                  <p>Confirmar a operação?</p>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handleExcludeModalConfirm}>SIM</div>
                  <div type="button" className="btn btn-danger" onClick={handleExcludeModalCancel}>NÃO</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Usuarios;